@import '../../styles/abstract.scss';

.default {
	color: $text-gray;
	padding: 20px 0;
	position: relative;
	width: 100%;
	color: $white;
	@include transition(all, .3s);
	z-index: 9;
	top: 0;
	left: 0;

	@include media-above(md) {
		position: absolute;
	}

	@media print {
		display: none;
	}

	&.isHeaderNotification {
		padding-top: 0;

		@include media-above(md) {
			padding-top: 40px;
		}
	}

	@include media-above(md) {
		position: fixed;
		z-index: 10;
		background-color: $white;
		color: inherit;

		&.isHeaderNotification {
			padding-top: 50px;
		}
	}

	@include media-below(md) {
		padding: 12px 0;
	}

	.logo {
		@include media-above(sm) {
			@include background-image-only($logo-positive);
		}

		@include media-below(sm) {
			@include background-image-only($logo-positive-mobile);
		}
	}

	&.scrolled {
		@include media-above(sm) {
			box-shadow: 0px 1px 1px #0000001A;

			&.down {
				top: -120px;
			}

			&.up {
				top: 0;
			}
		}
	}

	.navigation {
		> a:not([class*="btn"]) {
			&:hover {
				color: $primary-color;
			}
		}
	}

	.search {
		button {
			color: inherit;

			i {
				@include icon-image-only('search.svg');
			}
		}
	}

	button.mobileCta {
		&.light {
			span {
				background-color: $white;
			}
		}

		&[class*="open"] {
			position: fixed;
			right: 15px;
			top: 18px;

			span {
				background-color: $black;
			}
		}
	}

	nav {
		.isNotification {
			position: absolute;
			top: -1px;
			right: -3px;
			background-color: $danger-color;
			width: 11px;
			height: 11px;
			@include border-radius(50%);
			border: 2px solid $white;
		}

		> a {
			&:hover {
				.notifications {
					display: block;
				}
			}
		}

		[class*="tag_primary"] {
			width: 32px;
			text-align: center;
		}

		[class*="navigation_dropdown"] > nav > a:not([class*="btn"]):hover,
		[class*="header_notifications"] a:not([class*="btn"]):hover {
			&:after {
				display: none;
			}
		}

		a {
			&:not([class*="btn"]) {
				&:hover {
					color: inherit;

					@include media-below(md) {
						color: $gray-dark;
					}

					&:after {
						display: block;
					}
				}

				&:after {
					content: "";
					bottom: -10px;
					height: 2px;
					width: 100%;
					position: absolute;
					background-color: $gray-dark;
					display: none;
				}
			}
		}
	}
}

.account, .inside, .profile {
	box-shadow: 0px 1px 1px #0000001A;
}

.hero {
	box-shadow: none;

	&:not(.scrolled) {
		background-color: transparent;
		color: $white;

		.logo {
			@include media-above(sm) {
				@include background-image-only($logo-negative);
			}

			@include media-below(sm) {
				@include background-image-only($logo-negative-mobile);
			}
		}

		nav {
			a {
				&:not([class*="btn"]) {
					&:after {
						background-color: $white;
					}
				}
			}

			[class*="dropdown"] > button {
				color: $white;
			}
		}

		@include media-above(md) {
			[class*="notificationOption"] [class*="icon bell"] {
				@include icon-image-only('bell-regular-white.svg');
			}

			[class*="icon dropdown-arrow-down"] {
				@include icon-image-only('chevron-down-regular-white.svg');
			}
		}

		[class*="toggler"] span {
			background-color: $white;
		}
	}

	&.scrolled {
		box-shadow: 0px 1px 1px #0000001A;
	}
}

.container {
	@include flexbox();
	@include justify-content(space-between);
	@include align-items(center);
}

.logo {
	@include media-above(sm) {
		@include background-image('logo-drazbe-white.svg', 200, 32);
	}

	@include media-below(sm) {
		@include background-image('logo-drazbe-white-mobile.svg', 31, 32);
	}

	display: block;
}

.pullRight {
	@include flexbox();
	@include align-items(center);

	@include media-below(sm) {
		position: absolute;
	  right: 15px;
	  top: 6px;
	}

	> * {
		&:not(:last-child) {
			margin-right: 40px;

			@include media-below(md) {
				margin-right: 25px;
			}
		}
	}
}

.notificationOption {
	margin-right: 40px;
	padding-bottom: 0!important;

	@include media-below(md) {
		margin-right: 0;
		@include flex-direction(column);

		> i {
			display: none;
		}
	}

	button {
		height: 20px;
	}
}

.overlayClose {
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 10;
	background-color: transparent;
	left: 0;
	top: 0;
}

.notifications {
	@include dropdown-modal(0);
	width: 400px;
	margin-top: 20px;
	padding: 0;
	color: $text-gray;
	display: block;

	@include media-below(md) {
		position: relative!important;
		display: block;
		box-shadow: none;
		margin-top: 0;
		width: 100%;
	}

	.header {
		@include flexbox();
		@include justify-content(space-between);
		@include align-items(center);
		padding: 20px 0;

		button, a {
			margin: 0;
			color: $text-gray-light;

			@include media-below(md) {
				display: none;
			}
		}

		h4 {
			@include media-below(md) {
				width: 100%;
				text-align: center;
			}
		}

		a {
			i {
				margin-right: 0;
			}
		}
	}

	[class*="loader"] {
		margin-bottom: 20px;
	}

	[class*="notification_list__"] {
		max-height: 400px;
		overflow: auto;
		@include scrollbar();

		@include media-below(xs) {
			max-height: 300px;
		}

		[class*="listItem"] {
			[class*="notification_header"] {
				p, time {
					font-size: 14px;
				}
			}
		}

		a {
			margin-bottom: 0;

			i {
				margin-right: 0;
			}

			&:last-child {
				border-bottom: 0;
			}

			[class*="icon"] {
				top: 0;
			}
		}
	}

	.showAll {
		text-align: center;
		padding: 10px 0;
	}
}


.authed {
	button.mobileCta {
		display: none!important;
	}

	[class*="search_default"] {
		@include media-below(md) {
			margin-right: 0;
		}
	}
}

.notification {
	position: relative;
	color: $white;
	background-color: $danger-color;
	text-align: center;
	font-size: 14px;
	line-height: 18px;
	font-weight: $font-regular;
	padding: 7px 30px;
	top: 0;
	width: 100%;
	margin-bottom: 12px;

	&.hidden {
		display: none;
	}

	&.open {
		display: block;
	}

	@include media-above(md) {
		position: absolute;
		margin-bottom: 0;
	}

	&.graydark { background-color: $gray-dark; }
	&.graylight { background-color: $text-gray-light; }
	&.green { background-color: $text-green-special; }
	&.red { background-color: $danger-color; }

	a {
		font-weight: $font-medium;
		text-decoration: underline;
		color: inherit;
	}

	button {
		position: absolute;
		right: 15px;
		top: 0;
		bottom: 0;
		margin: auto;
	}
}
